export function walletClick(id: any, value: any, loyaltyId: any) {
  window.dataLayer.push({
    event: 'wallet_click',
    customer_id: id,
    reach_mode: 'drop_down',
    loyalty_id: loyaltyId,
    wallet_value: value,
  });
}

export function walletAddGiftCardStart(id: any, value: any, loyaltyId: any, path: string) {
  window.dataLayer.push({
    event: 'wallet_add_gift_card_start',
    customer_id: id,
    section: path,
    loyalty_id: loyaltyId,
    wallet_value: value,
  });
}

export function walletAddGiftCardSuccess(id: any, value: any, loyaltyId: any, path: string) {
  window.dataLayer.push({
    event: 'wallet_add_gift_card_success',
    customer_id: id,
    section: path,
    loyalty_id: loyaltyId,
    wallet_value: value,
  });
}

export function walletAddGiftCardFail(id: any, value: any, loyaltyId: any, path: string, errorMessage: string) {
  window.dataLayer.push({
    event: 'wallet_add_gift_card_fail',
    customer_id: id,
    section: path,
    loyalty_id: loyaltyId,
    wallet_value: value,
    error_message: errorMessage,
  });
}

export function walletRefundStart(id: any, value: any, loyaltyId: any, refund_value: any) {
  window.dataLayer.push({
    event: 'wallet_refund_start',
    customer_id: id,
    refund_value: refund_value,
    loyalty_id: loyaltyId,
    wallet_value: value,
  });
}

export function walletRefundSuccess(
  id: any,
  value: any,
  loyaltyId: any,
  refund_value: any,
  giftCardTriggered: number = 0,
) {
  window.dataLayer.push({
    event: 'wallet_refund_success',
    customer_id: id,
    refund_value: refund_value,
    loyalty_id: loyaltyId,
    wallet_value: value,
    gift_card_triggered: giftCardTriggered,
  });
}

export function walletRefundFail(id: any, value: any, loyaltyId: any, refund_value: any, error: string) {
  window.dataLayer.push({
    event: 'wallet_refund_fail',
    customer_id: id,
    refund_value: refund_value,
    loyalty_id: loyaltyId,
    wallet_value: value,
    error_message: error,
  });
}

export function walletToggleOff(CId: any, LId: any, balance: any) {
  window.dataLayer.push({
    event: 'wallet_toggle_off',
    loyalty_id: LId,
    customer_id: CId,
    wallet_value: balance,
  });
}

export function walletToggleOn(CId: any, LId: any, balance: any) {
  window.dataLayer.push({
    event: 'wallet_toggle_on',
    loyalty_id: LId,
    customer_id: CId,
    wallet_value: balance,
  });
}

export function walletToggleFail(CId: any, LId: any, balance: any) {
  window.dataLayer.push({
    event: 'wallet_toggle_fail',
    loyalty_id: LId,
    customer_id: CId,
    wallet_value: balance,
    order_value: '',
    error_message: '',
  });
}

export function walletPaySecurely(CId: any, LId: any, balance: any, orderValue: any) {
  window.dataLayer.push({
    event: 'wallet_pay_securely',
    loyalty_id: LId,
    customer_id: CId,
    wallet_value: balance,
    order_value: orderValue,
  });
}

export function walletPayStart(CId: any, LId: any, balance: any, orderValue: any) {
  window.dataLayer.push({
    event: 'wallet_pay_start',
    loyalty_id: LId,
    customer_id: CId,
    wallet_value: balance,
    order_value: orderValue,
  });
}

export function walletPaySuccess(CId: any, LId: any, balance: any, orderValue: any) {
  window.dataLayer.push({
    event: 'wallet_pay_success',
    loyalty_id: LId,
    customer_id: CId,
    wallet_value: balance,
    order_value: orderValue,
  });
}

export function walletPayFail(CId: any, LId: any, balance: any, orderValue: any, error: string) {
  window.dataLayer.push({
    event: 'wallet_pay_fail',
    loyalty_id: LId,
    customer_id: CId,
    wallet_value: balance,
    order_value: orderValue,
    error_message: error,
  });
}
