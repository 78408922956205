/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
/* eslint-disable import/no-extraneous-dependencies */
import { appSettingContent } from '@/constant/contentfulConstants';
import algoliasearch from 'algoliasearch/lite';
import axios from 'axios';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import safeStringify from 'fast-safe-stringify';
import jsonQuery from 'json-query';
import Router from 'next/router';
import qs from 'qs';
import { decrypt } from './Middleware/helpers';
import { getCookie, getCookies } from './Utils/Cookies';

dayjs.extend(advancedFormat);

export const isDecathlonEmail = () => {
  if (process.env?.NEXT_PUBLIC_FEATURE_FLAG_ED?.toLocaleLowerCase() === 'true') {
    const { dkt_em: email } = getCookies();
    const emailPattern = /^[A-Z0-9._%+-]+@decathlon\.com$/i;
    const partnerPattern = /^[A-Z0-9._%+-]+partner@decathlon\.com$/i;
    const isInternal = emailPattern.test(decrypt(email));
    const isPartner = partnerPattern.test(decrypt(email));
    const isInternalEmail = isInternal && !isPartner;
    return isInternalEmail;
  }
  return false;
};

export const scrollTo = (offset) => {
  window.scrollTo(0, offset);
};

const covertToUrl = (string) => {
  if (!string) return string;
  return string
    .toLocaleLowerCase()
    .replace(/[^a-zA-Z|0-9]/g, '-')
    .replace(/------|-----|----|---|--|-/g, '-');
};

export const getProductUrl = (data) => {
  if (!data?.modelId) return false;
  let productSlug = false;
  let productCategorySlug = false;

  if (data.productCategory && data.productCategory.slug && data.description.slug) {
    return `/p/${data.modelId}/${data.productCategory.slug}/${data.description.slug}`;
  }

  if (data?.catSlug) {
    productCategorySlug = data.catSlug;
  } else {
    productCategorySlug =
      data.productCategory && data.productCategory.name ? covertToUrl(data.productCategory.name) : 'category';
  }

  if (data?.productSlug) {
    productSlug = data.productSlug;
  } else {
    productSlug = data.description.productName ? covertToUrl(data.description.productName) : 'product';
  }

  return `/p/${data.modelId}/${productCategorySlug}/${productSlug}`;
};

export const getDateDiff = (date) => {
  const day = 1000 * 60 * 60 * 24;
  const fromDate = new Date();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const toDate = new Date(date.split('.')[0]);
  if (!isSafari) {
    toDate.setMinutes(toDate.getMinutes() + 30);
    toDate.setHours(toDate.getHours() + 5);
  }
  if (fromDate.getMonth() === 11 && fromDate.getDate() > 25) {
    toDate.setFullYear(toDate.getFullYear() + 1);
  }

  let result = (Math.round(toDate.getTime() - fromDate.getTime()) / day).toFixed(0);
  result = result === 0 ? 0 : Number(result) * -1;

  return result;
};

export const getTime = (hours) => {
  let time = hours.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [hours];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? 'AM' : 'PM';
    time[0] = +time[0] % 12 || 12;
  }
  return time.join(''); // return adjusted time or original string
};

export const Currency = (_value, decimal = false, curr = true) => {
  const value = Number(_value);
  if (!Number(value) && value !== 0) return _value || '';

  let amount = value.toString();
  const decimalNumber = amount.split('.')[1] || '00';
  amount = amount.split('.')[0];
  const otherNumbers = amount.substring(0, amount.length - 3);
  let lastThreeDigits = amount.substring(amount.length - 3);

  if (otherNumbers !== '') {
    lastThreeDigits = `,${lastThreeDigits}`;
  }

  let result = String.fromCodePoint(8377) + ' ';
  result += otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
  result += lastThreeDigits;

  if (decimalNumber && decimal) {
    result += `.${decimalNumber}`;
  }

  if (!curr) {
    result = result.slice(1, result.length);
  }

  return result;
};

export const getHost = (ctx = {}) => {
  return process.env.NEXT_PUBLIC_SITE_URL;
  // const host = ctx?.isServer || ctx?.req ? ctx.req.headers.host : window.location.host;
  // return `${host.indexOf('localhost') !== -1 ? 'http' : 'https'}://${host}`;
};

export const checkAccessToken = (ctx = {}) => {
  return '';
};

export const getAccessToken = async (ctx = {}) => {
  return false;
};

export const getCardTypeLogo = (type) => {
  let logo = null;
  const cardType = (type || '').replace(' ', '-');

  switch (cardType) {
    case 'american-express':
      logo = (
        <svg viewBox="0 0 291.8 210" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" d="M-1-1h293.8v212H-1z" />
          <g>
            <path
              fill="#26a6d1"
              d="M18.235 0h255.294c10.066 0 18.235 8.169 18.235 18.244v173.235c0 10.066-8.169 18.235-18.235 18.235H18.235C8.16 209.715 0 201.545 0 191.48V18.244C0 8.169 8.169 0 18.235 0z"
            />
            <path
              fill="#fff"
              d="M47.047 73.941l-28.812 63.76h34.492l4.276-10.166h9.774l4.276 10.166h37.966v-7.759l3.383 7.759h19.639l3.383-7.923v7.923h78.959l9.601-9.902 8.99 9.902 40.555.082-28.903-31.784 28.903-32.058h-39.926l-9.346 9.719-8.707-9.719h-85.897l-7.376 16.457-7.549-16.457h-34.42v7.495l-3.829-7.495H47.047zm6.674 9.054h16.813l19.111 43.236V82.995h18.418l14.761 31 13.604-31h18.326v45.752h-11.151l-.091-35.851-16.257 35.851h-9.975l-16.348-35.851v35.851h-22.94l-4.349-10.257H50.147l-4.34 10.248H33.516l20.205-45.743zm111.235 0h45.342l13.868 14.98 14.315-14.98h13.868l-21.071 22.995 21.071 22.73h-14.497l-13.868-15.154-14.388 15.154h-44.64V82.995zM61.9 90.736l-7.741 18.272h15.473L61.9 90.736zm114.253 1.732v8.352h24.736v9.309h-24.736v9.118h27.745l12.892-13.43-12.345-13.357h-28.292v.008z"
            />
          </g>
        </svg>
      );
      break;
    case 'diners-club':
    case 'discover':
      logo = (
        <svg viewBox="0 0 637 504" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" d="M-1 -1H581V401H-1z" />
          <g>
            <g fillRule="nonzero" fill="none">
              <path
                fill="#0079BE"
                d="m372.69,504.00288c137.75,0.65612 263.48,-112.34388 263.48,-249.81288c0,-150.33 -125.73,-254.24 -263.48,-254.19002l-118.55,0c-139.4,-0.04998 -254.14,103.89102 -254.14,254.19002c0,137.499 114.74,250.47 254.14,249.81288l118.55,0z"
              />
              <path
                fill="#FFF"
                d="m254.701,20.83c-127.38,0.04 -230.6,103.29 -230.63,230.721c0.03,127.409 103.249,230.649 230.63,230.689c127.41,-0.04 230.651,-103.28 230.669,-230.689c-0.019,-127.431 -103.259,-230.681 -230.669,-230.721zm-146.19,230.721l0,0c0.12,-62.27 39.01,-115.37 93.85,-136.471l0,272.91c-54.84,-21.089 -93.731,-74.16 -93.85,-136.439zm198.51,136.499l0,0l0,-272.979c54.86,21.05 93.81,74.18 93.91,136.48c-0.1,62.319 -39.05,115.409 -93.91,136.499z"
              />
              <path
                fill="#0079BE"
                d="m372.69,504.00288c137.75,0.65612 263.48,-112.34388 263.48,-249.81288c0,-150.33 -125.73,-254.24 -263.48,-254.19002l-118.55,0c-139.4,-0.04998 -254.14,103.89102 -254.14,254.19002c0,137.499 114.74,250.47 254.14,249.81288l118.55,0z"
              />
              <path
                fill="#FFF"
                d="m254.701,20.83c-127.38,0.04 -230.6,103.29 -230.63,230.721c0.03,127.409 103.249,230.649 230.63,230.689c127.41,-0.04 230.651,-103.28 230.669,-230.689c-0.019,-127.431 -103.259,-230.681 -230.669,-230.721zm-146.19,230.721l0,0c0.12,-62.27 39.01,-115.37 93.85,-136.471l0,272.91c-54.84,-21.089 -93.731,-74.16 -93.85,-136.439zm198.51,136.499l0,0l0,-272.979c54.86,21.05 93.81,74.18 93.91,136.48c-0.1,62.319 -39.05,115.409 -93.91,136.499z"
              />
            </g>
          </g>
        </svg>
      );
      break;
    case 'maestro':
      logo = (
        <svg viewBox="0 0 117 72" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" id="canvas_background" d="M-1 -1H118V73H-1z" />
          <g>
            <path id="svg_1" fill="none" d="M0 0H146.8V120.6H0z" />
            <g id="_Group_">
              <path id="svg_3" fill="#7673c0" d="M42.7 7.7H74.2V64.3H42.7z" />
              <path
                d="m44.727817,36.009162a35.94,35.94 0 0 1 13.75,-28.31a36,36 0 1 0 0,56.61a35.94,35.94 0 0 1 -13.75,-28.3z"
                id="_Path_"
                fill="#eb001b"
              />
              <path
                id="svg_4"
                d="m113.287817,58.309162l0,-1.12l0.47,0l0,-0.24l-1.19,0l0,0.24l0.47,0l0,1.16l0.25,-0.04zm2.06,0.04l0,-1.4l-0.36,0l-0.42,1l-0.42,-1l-0.37,0l0,1.4l0.26,0l0,-1.05l0.39,0.91l0.27,0l0.39,-0.91l0,1.06l0.26,-0.01z"
                fill="#00a1df"
              />
              <path
                id="svg_5"
                d="m116.717817,36.009162a36,36 0 0 1 -58.24,28.3a36,36 0 0 0 0,-56.61a36,36 0 0 1 58.25,28.3l-0.01,0.01z"
                fill="#00a1df"
              />
            </g>
          </g>
        </svg>
      );
      break;
    case 'mastercard':
      logo = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.4 86.9">
          <path fill="#ff5f00" d="M48.4 15.1H83.1V71.7H48.4z" />
          <path
            d="M51.94,43.45a35.94,35.94,0,0,1,13.75-28.3,36,36,0,1,0,0,56.61A35.94,35.94,0,0,1,51.94,43.45Z"
            fill="#eb001b"
          />
          <path
            fill="#f79e1b"
            d="M120.5 65.76V64.6H121v-.24h-1.19v.24h.47v1.16zm2.31 0v-1.4h-.36l-.42 1-.42-1h-.36v1.4h.26V64.7l.39.91h.27l.39-.91v1.06zM123.94 43.45a36 36 0 01-58.25 28.3 36 36 0 000-56.61 36 36 0 0158.25 28.3z"
          />
        </svg>
      );
      break;
    case 'visa':
      logo = (
        <svg viewBox="0 0 1000.2 323.7" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" d="M-1 -1H581V401H-1z" />
          <g>
            <path
              d="m433.517704,319.031341l-81.010679,0l50.669882,-313.304491l81.006249,0l-50.665453,313.304491zm293.670908,-305.64509c-15.978866,-6.339273 -41.322667,-13.33861 -72.660204,-13.33861c-80.000648,0 -136.336449,42.660515 -136.681986,103.652204c-0.664494,44.999534 40.334786,69.993368 70.998969,84.997643c31.341967,15.332092 41.996021,25.339371 41.996021,39.005798c-0.318957,20.98915 -25.326081,30.664183 -48.649821,30.664183c-32.343138,0 -49.673141,-4.988135 -76.009254,-16.66551l-10.667344,-5.005855l-11.336268,70.330045c19.000098,8.656142 54.005642,16.337692 90.349034,16.674369c85.002072,0 140.341133,-42.000451 140.996767,-106.996824c0.323387,-35.665608 -21.325827,-62.994031 -67.999886,-85.32546c-28.334024,-14.335351 -45.686177,-24.001523 -45.686177,-38.669121c0.332247,-13.33418 14.676457,-26.991746 46.660769,-26.991746c26.336112,-0.668924 45.686177,5.661489 60.344915,11.996332l7.327154,3.3269l11.017311,-67.654349l0,0zm107.670178,194.652442c6.67152,-17.998927 32.343138,-87.660048 32.343138,-87.660048c-0.336677,0.668924 6.65823,-18.331174 10.658484,-29.995259l5.661489,26.996176c0,0 15.340951,74.994793 18.672281,90.659131c-12.660826,0 -51.334376,0 -67.335392,0l0,0zm99.997487,-202.311843l-62.661784,0c-19.323486,0 -34.004373,5.661489 -42.341558,25.995005l-120.331003,287.305056l85.002072,0c0,0 13.994244,-38.669121 17.002186,-46.997446c9.325066,0 92.014699,0 104.011031,0c2.325729,10.999591 9.666173,46.997446 9.666173,46.997446l75.008083,0l-65.3552,-313.300061l0,0zm-650.012461,0l-79.336154,213.643681l-8.669432,-43.329439c-14.667598,-49.996529 -60.668302,-104.316698 -112.002679,-131.326164l72.669064,273.984166l85.666566,0l127.33477,-312.972244l-85.662137,0l0,0z"
              fill="#00579f"
            />
            <path
              fill="#faa61a"
              d="m131.837428,5.72685l-130.338283,0l-1.333418,6.330413c101.672012,25.999435 169.007404,88.670079 196.672504,163.997119l-28.334024,-143.99142c-4.664748,-20.010129 -19.000098,-25.676048 -36.666779,-26.336112l0,0z"
            />
          </g>
        </svg>
      );
      break;
    case 'bajaj':
      logo = (
        <svg viewBox="0 0 140.7 140.7" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" d="M-1 -1H581V401H-1z" />
          <g>
            <path
              fill="#0069b4"
              d="m114.23,88.35c0,19.17 -21.42,21 -36.57,21.09l-13.63,0l0,-17.26l27.1,0l0,-13.17l-27.1,0l0,-17.9l30,0l0,-13.92l-45.89,0l0,62.28l-17.53,0l0,-78.28l47.07,0c14,0 36.57,2 36.57,20.52c0,9.16 -7.85,15.9 -17,18.21c11.34,3.5 16.98,9.12 16.98,18.43zm-43.9,-88.35a70.33,70.33 0 1 0 70.33,70.33a70.33,70.33 0 0 0 -70.31,-70.33l-0.02,0z"
            />
          </g>
        </svg>
      );
      break;
    case 'rupay':
      logo = (
        <svg viewBox="0 0 295 80" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" d="M-1 -1H581V401H-1z" />
          <g>
            <path
              d="m148.316,23.713c-2.247,6.269 -8.44,5.531 -8.44,5.531l-8.456,0.003l3.339,-12.422c0,0 5.351,0.028 8.887,0.028c4.327,0 5.898,3.437 4.67,6.86l0,0zm17.758,-5.805c1.373,-10.872 -6.912,-15.409 -17.256,-15.409l-27.178,0l-16.042,58.471l17.27,0l4.856,-18.027l13.76,0.086c-0.001,-0.003 21.307,0.9 24.59,-25.121m-98.657,0.58l15.752,0l-6.488,24.96c0,0 -1.617,5.564 3.589,5.994c4.112,0.34 7.173,-4.567 8.177,-7.893c1.317,-4.366 6.438,-23.063 6.438,-23.063l16.227,0l-12.349,42.482l-14.169,0l1.742,-6.068c0,0 -7.229,8.813 -17.969,7.758c-9.546,-0.938 -10.369,-7.862 -8.715,-16.502c0.811,-4.239 7.765,-27.668 7.765,-27.668"
              fill="#2E3187"
            />
            <path
              d="m180.594,52.315c-2.697,0.687 -5.97,1.057 -6.619,-1.395c-1.781,-6.719 13.947,-8.686 13.947,-8.686c0.107,6.122 -5.256,9.554 -7.328,10.081l0,0zm23.795,-12.296c2.076,-7.065 4.697,-13.758 2.586,-17.466c-3.232,-5.677 -9.078,-6.176 -17.627,-6.176c-9.444,0 -21.106,1.795 -24.908,14.355l15.728,0c0,0 1.433,-4.731 7.335,-4.433c5.225,0.264 4.938,3.859 3.014,5.845c-3.377,3.483 -12.703,1.551 -23.029,5.135c-9.023,3.13 -12.182,14.998 -10.223,19.733c1.899,4.591 5.438,5.177 10.211,5.646c7.668,0.754 13.545,-3.521 16.281,-6.028c0,2.785 0.071,4.341 0.071,4.341l16.549,0l-0.041,-1.557c0,0 -1.474,-0.391 -1.309,-2.463c0.118,-1.518 2.977,-8.815 5.362,-16.932m-159.211,-16.439c-2.233,5.173 -9.157,4.584 -9.157,4.584l-8.061,-0.037l2.943,-10.955c0,0 7.212,0.027 10.748,0.027c3.786,0 4.969,3.041 3.527,6.381zm18.321,-7.466c1.372,-10.871 -8.192,-13.615 -18.536,-13.615l-27.178,0l-16.043,58.471l17.27,0l5.392,-19.854l9.688,0.08c0,0 3.998,-0.232 4.076,3.521c0.085,4.005 -2.968,11.359 -2.77,16.251l17.742,0l-0.04,-1.557c0,0 -1.474,-0.392 -1.31,-2.464c0.068,-0.86 1.02,-3.588 2.266,-7.268c0.75,-1.621 1.884,-5.469 1.779,-8.602c-0.131,-3.91 -2.584,-5.725 -6.123,-6.995c11.03,-2.581 13.787,-17.968 13.787,-17.968m148.384,2.374l16.043,0l0,25.49l13.877,-25.49l14.726,0l-29.685,51.533c0,0 -2.756,4.322 -6.176,6.609c-2.809,1.881 -6.26,1.795 -7.314,1.871c-5.864,-0.051 -12.938,-0.066 -12.938,-0.066l3.412,-12.283l5.521,-0.01c0,0 2.525,-0.258 3.502,-1.505c0.931,-1.187 1.404,-2.374 1.404,-4.116c0.001,-2.612 -2.374,-42.033 -2.372,-42.033"
              fill="#2E3187"
            />
            <path d="m277.108,8.673l-18.523,64.434l34.673,-32.218l-16.15,-32.216" fill="#278043" />
            <path d="m265.234,8.673l-18.523,64.434l34.672,-32.218l-16.149,-32.216" fill="#F06019" />
          </g>
        </svg>
      );
      break;
    default:
      logo = (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" version="1">
          <path
            d="M22.68,12.68 L22.68,11 L9.32,11 L9.32,12.68 L22.68,12.68 Z M22.68,21 L22.68,16 L9.32,16 L9.32,21 L22.68,21 Z M22.68,9.32 C23.146669,9.32 23.5366651,9.4833317 23.85,9.81 C24.1633349,10.1366683 24.32,10.533331 24.32,11 L24.32,21 C24.32,21.466669 24.1633349,21.8633317 23.85,22.19 C23.5366651,22.5166683 23.146669,22.68 22.68,22.68 L9.32,22.68 C8.853331,22.68 8.4633349,22.5166683 8.15,22.19 C7.8366651,21.8633317 7.68,21.466669 7.68,21 L7.68,11 C7.68,10.533331 7.8366651,10.1366683 8.15,9.81 C8.4633349,9.4833317 8.853331,9.32 9.32,9.32 L22.68,9.32 Z"
            transform="translate(-7 -9)"
            fill="#072654"
            fillRule="evenodd"
          />
        </svg>
      );
      break;
  }

  return logo;
};

export const getPlatform = (ctx = {}) => {
  const decathlonApp =
    getCookie('decathlonApp', {
      req: ctx?.req,
      res: ctx?.res,
    }) || ctx?.query?.platform;

  const userAgent = typeof window === 'object' ? window.navigator.userAgent : ctx?.req?.headers['user-agent'];

  if (decathlonApp === 'ANDROID' || decathlonApp === 'IOS') return 'APP';

  if (userAgent && userAgent.indexOf('phonepe-webview') !== -1) return 'PHONEPE_SWITCH';

  if (userAgent && userAgent.indexOf('AppContainer') !== -1) return 'PAYTM_MINI';

  return 'WEB';
};

export const toggleSearchElemets = (toggle, searchI) => {
  if (searchI) {
    if (document.querySelector('.category_no_result')) {
      document.querySelector('.category_no_result').className = 'category_no_result hidden';
    }
    if (toggle) {
      if (document.querySelector('.algolia_search')) {
        document.querySelector('.algolia_search').style.display = 'block';
      }
      if (document.querySelector('.algolia_no_result')) {
        document.querySelector('.algolia_no_result').className = 'algolia_no_result hidden';
      }
    } else {
      if (document.querySelector('.algolia_search')) {
        document.querySelector('.algolia_search').style.display = 'none';
      }
      if (document.querySelector('.algolia_no_result')) {
        document.querySelector('.algolia_no_result').className = 'algolia_no_result';
      }
    }
  } else {
    if (document.querySelector('.algolia_no_result')) {
      document.querySelector('.algolia_no_result').className = 'algolia_no_result hidden';
    }
    if (toggle) {
      if (document.querySelector('.category_search')) {
        document.querySelector('.category_search').style.display = 'block';
      }
      if (document.querySelector('.category_no_result')) {
        document.querySelector('.category_no_result').className = 'category_no_result hidden';
      }
    } else {
      if (document.querySelector('.category_search')) {
        document.querySelector('.category_search').style.display = 'none';
      }
      if (document.querySelector('.category_no_result')) {
        document.querySelector('.category_no_result').className = 'category_no_result';
      }
    }
  }
};

export const getProductByModelId = (payload) => {
  return jsonQuery(`[id=${payload.modelId}]`, { data: payload }).value;
};

export const deleteEmptyParametersCategory = (searchState) => {
  Object.entries(searchState).forEach(([key, value]) => {
    if (key == 'query') {
      delete searchState.query;
    } else if (key == 'page') {
      if (searchState.page == 1) {
        delete searchState.page;
      }
    } else if (key == 'configure') {
      delete searchState.configure;
    } else {
      Object.entries(value).forEach(([key1, value1]) => {
        if (value1.length == 0) {
          delete searchState.refinementList[key1];
        }
      });
    }
  });

  return searchState;
};

export const deleteEmptyParametersSearch = (searchState) => {
  Object.entries(searchState).forEach(([key, value]) => {
    if (key == 'query') {
      if (searchState.query == '') {
        delete searchState.query;
      }
    } else if (key == 'page') {
      if (searchState.page == 1) {
        delete searchState.page;
      }
    } else if (key == 'configure') {
      delete searchState.configure;
    } else {
      Object.entries(value).forEach(([key1, value1]) => {
        if (value1.length == 0) {
          delete searchState.refinementList[key1];
        }
      });
    }
  });

  return searchState;
};

//count formate (999+)
export const filterAttributeCountFormat = (c) => {
  const count = Number.parseInt(c, 10);
  return count > 999 ? '999+' : count;
};

// CONTENTFUL //
const contentful = require('contentful');

if (process.env.isServer) {
  axios.defaults.timeout = 10000;
}
// Initialize contentuful sdk
export const contentfulClient = contentful.createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
  timeout: process.env.isServer ? 5000 : 10000,
  retryLimit: 3,
});

export const getContentfulClientEntries = async (payload, res, retry = true) => {
  try {
    const contentfulEntries = await contentfulClient.getEntries({ ...payload, 'fields.tenant[nin]': ['B2B', 'DPLAY'] });
    return JSON.parse(safeStringify(contentfulEntries));
  } catch (err) {
    if (retry) {
      console.log('Caught Exception!!: In getContentfulClientEntries() , auto retring after a second');
      // wait for a second
      await new Promise((resolve) => setTimeout(() => resolve(), 2000));
      await getContentfulClientEntries(payload, res, false);
    } else {
      console.error('getContentfulClientEntries error!!', payload, err.stack);
      if (res) {
        console.log('contenful error!!- Server-side', err);
        res.writeHead(302, {
          Location: '/500',
        });
        res.end();
      } else if (!process.env.isServer) {
        console.error('contenful error!!- Client-side', err);
        Router.push('/500');
      }
      console.info('Caught Exception!! Redirecting to Home Page');
    }
    return null;
  }
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const lqipImageSwitch = () => {
  const images = document.getElementsByClassName('img-lqip');
  for (let i = 0; i < images.length; i++) {
    if (images[i].getAttribute('data-src') != images[i].getAttribute('src')) {
      images[i].setAttribute('src', images[i].getAttribute('data-src'));
      images[i].classList.add('img-orignial');
    }
  }
};

// Search functions start here
export const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_CLIENT_SECRET,
  {
    _useRequestCache: true,
  },
);

export const pathToSearchState = (path) => (path.includes('?') ? qs.parse(path.substring(path.indexOf('?') + 1)) : {});

export const searchStateToURL = (searchState) =>
  searchState ? `${window.location.pathname}?${qs.stringify(searchState)}` : '';

export const searchClient = {
  search(requests) {
    if (typeof window === 'object' && window?.innerWidth < 720) {
      const hitsPerPage = 10;
      requests = requests.map((arr) => {
        return {
          ...arr,
          params: { ...arr.params, facetingAfterDistinct: true, hitsPerPage },
        };
      });
    } else {
      requests = requests.map((arr) => ({ ...arr, params: { ...arr.params, facetingAfterDistinct: true } }));
    }
    const shouldSearch = requests.some(({ params: { query } }) => query !== '' && query?.length !== 0);
    if (shouldSearch) {
      return algoliaClient.search(requests);
    }
    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
};

export const DEFAULT_PROPS = {
  searchClient,
  indexName: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX,
};
// Search functions end here

// Function to escape special characters
export const escapeRegex = (string) => {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

const readyPayTm = (callback) => {
  // call if jsbridge is injected
  if (window.JSBridge) {
    callback && callback();
  } else {
    // listen to jsbridge ready event
    document.addEventListener('JSBridgeReady', callback, false);
  }
};

export const jsBridgePaytm = async (api, req) => {
  return await new Promise((resolve, reject) => {
    readyPayTm(() => {
      JSBridge.call(api, req, function (result) {
        resolve(result);
      });
    });
  });
};

export const deviceDetector = ({ req, platform }) => {
  let userAgent = null;

  if (req && req.headers['user-agent']) {
    userAgent = req.headers['user-agent'];
  }
  if (typeof window !== 'undefined') {
    userAgent = navigator.userAgent;
  }

  if (platform !== 'WEB') {
    return 'mobile';
  }

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent,
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
};

export const timeConvert = (time24) => {
  const timeHours = +time24.substr(0, 2);
  const ampm = timeHours < 12 ? 'AM' : 'PM';
  let hours = timeHours % 12 || 12;
  hours = hours < 10 ? `0${hours}` : hours;
  const time12 = hours + time24.substr(2, 3) + ampm;

  return time12;
};

export const getCorrectDate = (datetime, getDateTime = false, forDiff = false) => {
  if (datetime) {
    const au = datetime.split('+')[0];
    const createdDate = new Date(au);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      //
    } else {
      createdDate.setMinutes(createdDate.getMinutes() + 30);
      createdDate.setHours(createdDate.getHours() + 5);
    }

    if (getDateTime) {
      let hours = createdDate.getHours();
      let time = 'AM';
      let minutes = createdDate.getMinutes();
      let seconds = createdDate.getSeconds();

      if (hours >= 12) {
        if (hours > 12) {
          hours -= 12;
        }
        time = 'PM';
      }

      if (hours === 0) {
        hours = `0${hours}`;
      }

      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      if (forDiff) {
        return `${createdDate.getFullYear()}-${
          createdDate.getMonth() + 1
        }-${createdDate.getDate()} ${hours}:${minutes}:${seconds} ${time}`;
      }

      return `${createdDate.getDate()}-${
        createdDate.getMonth() + 1
      }-${createdDate.getFullYear()} ${hours}:${minutes} ${time}`;
    }

    return `${createdDate.getDate()}-${createdDate.getMonth() + 1}-${createdDate.getFullYear()}`;
  }
  return '';
};

export const formatDate = (datetime) => {
  if (datetime) {
    return dayjs(datetime).format('Do MMM, YYYY');
  }
  return '';
};

export const getDateText = (datetime, flag = true) => {
  if (datetime) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const au = datetime.split('+')[0];
    const createdDate = new Date(au);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      //
    } else {
      createdDate.setMinutes(flag ? createdDate.getMinutes() + 30 : createdDate.getMinutes());
      createdDate.setHours(flag ? createdDate.getHours() + 5 : createdDate.getHours());
    }

    const nthNumber = (number) => {
      if (number > 3 && number < 21) return 'th';
      switch (number % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    const month = monthNames[createdDate.getMonth()];
    return flag
      ? `${createdDate.getDate()} ${month} ${createdDate.getFullYear()}`
      : `${createdDate.getDate()}${nthNumber(createdDate.getDate())} ${month}, ${createdDate.getFullYear()}`;
  }
  return '';
};

export const wcmsGtmfields = (fields) => {
  if (fields) {
    return {
      brand: fields.brand,
      familyId: fields.familyId,
      life_cycle: fields.lifeCycle,
      nature: fields.nature,
      sport: fields.sport,
      sport_practice: fields.sportPractice,
      sport_group: fields.sportGroup,
      superModel: fields.superModel,
      articleId: fields.articleId,
      variant: fields.variant,
    };
  }
  return {};
};

export const getDeliveryTypeDateHTML = (
  orderType,
  currentStatus,
  deliveredDate,
  estimatedDate,
  activeProductInfo = null,
) => {
  let html = '';

  if (activeProductInfo?.returnStatusTitle && !activeProductInfo?.replacementStatus) {
    return (html = (
      <>
        {activeProductInfo.returnStatusTitle} on {getDateText(activeProductInfo.returnStatusUpdatedDate)}
      </>
    ));
  }

  if (
    orderType === 'DELIVERY' ||
    orderType === 'WH_HOME_DELIVERY' ||
    orderType === 'STORE_HOME_DELIVERY' ||
    orderType === 'HUB_HOME_DELIVERY'
  ) {
    if (currentStatus.isDelivered) {
      if (deliveredDate) {
        html = <>Delivered on {getDateText(deliveredDate)}</>;
      }
    } else if (estimatedDate) {
      html = <>Delivery By {getDateText(estimatedDate)}</>;
    }
  }

  if (
    orderType === 'RESERVE_COLLECT' ||
    orderType === 'CLICK_COLLECT' ||
    orderType === 'CLICK_CONNECT' ||
    orderType === 'LOCKER'
  ) {
    if (currentStatus.isDelivered) {
      if (deliveredDate) {
        html = <>Picked Up On {getDateText(deliveredDate)}</>;
      }
    } else if (estimatedDate) {
      html = <>Pickup By {getDateText(estimatedDate)}</>;
    }
  }

  if (orderType === 'TAKEAWAY' && deliveredDate) {
    html = <>Purchased on {getDateText(deliveredDate)}</>;
  }

  return html;
};

export const getDisplayDeliveryTypeTxt = (orderType) => {
  let type = '';
  if (
    orderType === 'DELIVERY' ||
    orderType === 'WH_HOME_DELIVERY' ||
    orderType === 'STORE_HOME_DELIVERY' ||
    orderType === 'HUB_HOME_DELIVERY'
  ) {
    type = 'Home delivery';
  }

  if (orderType === 'RESERVE_COLLECT' || orderType === 'CLICK_COLLECT' || orderType === 'CLICK_CONNECT') {
    type = 'Pickup from store';
  }

  if (orderType === 'LOCKER') {
    type = 'Pickup from locker';
  }

  if (orderType === 'TAKEAWAY') {
    type = 'Scan & Pay';
  }

  return type;
};

export const displayPurchaseChannel = (channel) => {
  switch (channel) {
    case 'APP':
      return 'Mobile Application Purchase';

    case 'WEB':
      return 'Website Purchase';

    case 'PAYTM_MINI':
      return 'Paytm Mini Purchase';

    case 'PHONEPE_SWITCH':
      return 'PhonePe Switch Purchase';

    default:
      return 'Website Purchase';
  }
};

export const chatBotTrigger = (chatBotTime, chatBotTrigger, pathname) => {
  const pagePathname = {
    '/': 'homepage',
    '/p/[...product]': 'productpage',
    '/[...category]': 'categorypage',
    '/shop/[...shop]': 'shoppage',
  };

  if (!chatBotTrigger?.includes(pagePathname[pathname] || '')) {
    return false;
  }

  const now = new Date();
  const weekday = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const currentDay = weekday[now.getDay()];
  const startTime = Number(chatBotTime?.[currentDay]?.startTime);
  const endTime = Number(chatBotTime?.[currentDay]?.endTime);

  return now.getHours() >= startTime && now.getHours() < endTime;
};

export const getRatingColor = (index) => {
  if (index === 3 || index === 2) return '#ffc800';
  if (index === 1) return '#e20c18';
  return '#00ac6a';
};

export const formatedDate = (date = '') => {
  let arr = date.split('-');
  let formated_date = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    formated_date.push(arr[i]);
  }
  if (formated_date[1]?.length === 1) {
    formated_date[1] = `0${formated_date[1]}`;
  }
  if (formated_date[2]?.length === 1) {
    formated_date[2] = `0${formated_date[2]}`;
  }
  return formated_date.join('-');
};

export const formatedSearchBarKeywords = (words) => {
  const formattedKeywords = [];
  const animationCursorTime = 2000;
  words.forEach((e) => {
    formattedKeywords.push(`"${e}"`);
    formattedKeywords.push(animationCursorTime);
  });
  return formattedKeywords;
};

export const debouncer = (func, delay) => {
  clearTimeout(func._tId);
  func._tId = setTimeout(function () {
    func();
  }, delay);
};

// get the facets config from wcms
export const getWcmsFacetDisplay = async () => {
  let wcmsFacetDisplayConfig;
  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);
  const cacheData = localStorage.getItem('filterAttribute');
  const filterAttributeEpoch = localStorage.getItem('filterAttributeEpoch');
  const isfilterAttributeInLocalStorage =
    filterAttributeEpoch !== null
      ? secondsSinceEpoch - filterAttributeEpoch < process.env.NEXT_PUBLIC_FILTER_ATTRIBUTE_BROWSER_CACHE_TIME_SECONDS
      : false;
  if (!isfilterAttributeInLocalStorage || !cacheData) {
    let result;
    try {
      result = await getContentfulClientEntries({
        content_type: appSettingContent.type,
        [appSettingContent.key]: 'algolia_Filters_Web',
        select: appSettingContent.select,
      });
    } catch (e) {
      result = [];
      console.error(e);
    }
    wcmsFacetDisplayConfig = JSON.parse(result.items[0].fields.value) ?? [];
    localStorage.setItem('filterAttribute', JSON.stringify(wcmsFacetDisplayConfig));
    localStorage.setItem('filterAttributeEpoch', secondsSinceEpoch);
  }
};
export const capitaliseFirstLetter = (name) => {
  return typeof name === 'string' ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : name;
};

export const supportLink = `${process.env.NEXT_PUBLIC_SITE_URL}${process.env.NEXT_PUBLIC_SUPPORT_LINK}`;

export const storeRecentSearchQueries = (params) => {
  const [name, image, redirect, URL] = params;
  if (name === undefined || image === undefined) return;
  if (typeof window === undefined) return;
  const recents = JSON.parse(window.localStorage.getItem('recent-search-query')) || [];
  if (recents.some((query) => query.term === name)) return;
  const query = {
    term: name,
    imageUrl: image,
    redirect: redirect,
    url: URL,
  };
  recents.unshift(query);
  if (recents.length > 10) recents.pop();
  window.localStorage.setItem('recent-search-query', JSON.stringify(recents));
};

export const updateRecentSearchQueries = (params) => {
  window.localStorage.setItem('recent-search-query', JSON.stringify(params));
};

export const storeRecentViewedProduct = (productDetails) => {
  const productId = productDetails.modelId;
  let newModels = [];
  const models = JSON.parse(window.localStorage.getItem('recently-viewed') || '[]');
  if (models.includes(productId)) {
    newModels = models.filter((model) => model !== productId);
    newModels.unshift(productId);
  } else if (models.length === 20) {
    models.pop();
    models.unshift(productId);
    newModels = [...models];
  } else {
    models.unshift(productId);
    newModels = [...models];
  }
  window.localStorage.setItem('recently-viewed', JSON.stringify(newModels));
};

export const sortReviews = (reviews) => {
  return reviews?.sort((a, b) => {
    // Sort by country code first: Indian reviews should come before all others
    if (a?.country?.code === 'IN' && b?.country?.code !== 'IN') {
      return -1;
    } else if (a?.country?.code !== 'IN' && b?.country?.code === 'IN') {
      return 1;
    }

    // Sort by publisherDate in descending order
    const dateA = new Date(a?.publisherDate);
    const dateB = new Date(b?.publisherDate);
    return dateB - dateA;
  });
};

export const pluralize = (count, singular, plural) => {
  return count === 1 ? singular : plural;
};

export const getCountryName = (code) => {
  const validRegionRegex = /^[A-Z]{2}$/;
  if (!validRegionRegex.test(code)) {
    return '';
  }

  try {
    const displayNames = new Intl.DisplayNames(['en-IN'], { type: 'region' });
    const countryName = displayNames.of(code);

    // Check invalid case
    if (countryName === code) {
      return '';
    }

    return countryName || '';
  } catch (error) {
    return '';
  }
};
